








































#main-container{
  height:100%;
  width:100%;
  overflow:hidden !important;
}

.iframe-container{
  height:100% !important;
}

header{
  display:none !important;
}

.not-allowed-bills{
  text-align: center;
  margin-top: 50px;
  color: #FF9B00;
  font-weight: 500;
}

